$bx--sm: 672px;
$bx--md: 1056px;
$bx--lg: 1312px;
$bx--xlg: 1584px;

////////// bootstrap overridden css

.image-modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  height: 20em;
  overflow-y: scroll;
  padding: $modal-inner-padding;
}

.image-modal-body {
  height: 30em;
}

.card-header {
  background-color: $white;
}

.modal-header-kz {
  font-size: 20px;
  font-weight: 400;
}

.modal-content-kz {
  padding: 24px 10px 0px 24px;
}

.modal-body-kz {
  padding: 25px 15px 0px 0px !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

////////// core ui overridden css

// app-dashboard and app-root are Angular2 selectors. You can add here your own selectors if you need.
.app,
beaconstac-dashboard,
beaconstac-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@mixin switch($type, $width, $height, $font-size, $handle-margin) {
  @if $type == text or $type == icon {
    .switch-label::before {
      color: $gray-dark;
    }
  }
}

.app-header.navbar {
  position: relative;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-block;
    width: $navbar-brand-width;
    height: $navbar-brand-height;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-color: $navbar-brand-bg;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
    @include borders($navbar-brand-border);
  }

  .navbar-toggler {
    color: $navbar-color;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 20px;
    margin: 12px;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
    padding-left: 20px !important;
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0,0,0,.075);
}


.sidebar.nav.nav-item.nav-link {
  color: $sidebar-nav-dropdown-color;
  font-size: 13px;

  &.active {
    i {
      color: $white;
    }
  }
}

.app-header.navbar.navbar-brand {
  height: $navbar-brand-height;
}

///////// patch 2

// IE10&11 Flexbox fix
@media all and (-ms-high-contrast:none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

// app-dashboard and app-root are Angular2+ selectors. You can add here your own selectors if you need.
.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-footer {
  flex: 0 0 $footer-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

// for sticky preview in desktop
@media only screen and (min-width: 673px) {
  .app-body{
    overflow-x: unset;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100vw;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//
// Sidebar
//
.sidebar-hidden {

  .sidebar {
    margin-left: - $sidebar-width !important;
  }
}

.sidebar-fixed {

  .sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;

    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .main {
    min-height: 90vh;
  }

  .main, .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {

    .main, .app-footer {
      margin-left: 0;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: 100%;


    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }

    &.sidebar-hidden {

      .sidebar {
        margin-left: - $sidebar-compact-width !important;
      }
    }

    &.sidebar-fixed {

      .main, .app-footer {
        margin-left: $sidebar-compact-width !important;
      }

      .sidebar {
        width: $sidebar-compact-width;
      }

      &.sidebar-hidden {

        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-hidden {

      .sidebar {
        margin-left: - $sidebar-minimized-width !important;
      }
    }

    &.sidebar-fixed {

      .main, .app-footer {
        margin-left: $sidebar-minimized-width !important;
      }

      .sidebar {
        width: $sidebar-minimized-width;
      }

      &.sidebar-hidden {

        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}

//
// Aside Menu
//
.aside-menu-hidden {

  .aside-menu {
    margin-right: - $aside-menu-width;
  }
}

.aside-menu-fixed {

  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }

  .main, .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {

    .main, .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

//
// Breadcrumb
//
.breadcrumb-fixed {

  .main {
    $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
    padding-top: $breadcrumb-height;
  }

  .breadcrumb {
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    z-index: $zindex-sticky - 2;
  }

  // if sidebar + main + aside
  .main:nth-child(2) {
    .breadcrumb {
      right: $aside-menu-width;
      left: $sidebar-width;
    }
  }

  // if sidebar + main
  .main:first-child {
    .breadcrumb {
      right: $aside-menu-width;
      left: 0;
    }
  }

  // if  main + aside
  .main:last-child {
    .breadcrumb {
      right: 0;
    }
  }

  &.sidebar-minimized {
    .main .breadcrumb {
      left: $sidebar-minimized-width;
    }
  }

  &.sidebar-hidden, &.sidebar-off-canvas {
    .main .breadcrumb {
      left: 0;
    }
  }

  &.aside-menu-hidden, &.aside-menu-off-canvas {
    .main .breadcrumb {
      right: 0;
    }
  }
}

//
// Footer
//
.footer-fixed {

  .app-footer {
    position: fixed;
    bottom: 0;
    z-index: $zindex-sticky;
    width: 100vw;
  }

  .app-body {
    margin-bottom: $footer-height;
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}
.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {

  .nav-margin-top {
    margin-top: 58px;
  }
  .app-header {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100vw;
    text-align: center;

    .navbar-toggler {
      @if (lightness( $navbar-brand-bg ) > 40) {
        color: $navbar-color;
      } @else {
        color: #fff;
      }
    }

    .navbar-brand {
      position: absolute;
      left: 50%;
      margin-left: - ($navbar-brand-width / 2);
    }
  }

  .app-body {
    margin-top: $navbar-height;
  }

  .sidebar {
    position: fixed;
    width: $mobile-sidebar-width;
    height: 100%;
    margin-left: - $mobile-sidebar-width !important;

    .sidebar-nav,
    .nav {
      width: $mobile-sidebar-width !important;
    }
  }

  .main, .app-footer {
    margin-left: 0 !important;
  }

  // .aside-menu {
  //   margin-right: - $aside-menu-width;
  // }

  .sidebar-hidden {
    .sidebar {
      margin-left: - $mobile-sidebar-width !important;
    }
  }

  .sidebar-mobile-show {

    .sidebar {
      width: $mobile-sidebar-width;
      margin-left: 0 !important;

      .sidebar-nav {
        height: calc(100vh - #{$navbar-height});
      }
    }

    .main {
      margin-right: - $mobile-sidebar-width !important;
      margin-left: $mobile-sidebar-width !important;
    }
  }

  .breadcrumb-fixed {
    .main .breadcrumb {
      right: 0 !important;
      left: 0 !important;
    }
  }
}

.card-block {
  @extend .card-body;
}


.breadcrumb {
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: 1rem;
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($border-radius);
  @include clearfix;
}

.breadcrumb-item {
  float: left;

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  .breadcrumb-item::before {
    display: inline-block; // Suppress underlining of the separator in modern browsers
    padding-right: $breadcrumb-item-padding;
    padding-left: $breadcrumb-item-padding;
    color: $breadcrumb-divider-color;
    content: "#{$breadcrumb-divider}";
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}

// scss-lint:disable NestingDepth, SelectorDepth
.sidebar {
  padding: $sidebar-padding;
  overflow: hidden;
  color: $sidebar-color;
  background: #FFFFFF;
  @include borders($sidebar-borders);

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-nav {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $sidebar-width);

    &::-webkit-scrollbar {

      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none;
    }

    // &::-webkit-scrollbar-button {    }

    &::-webkit-scrollbar-track {
      background-color: lighten($sidebar-bg, 5%);
      border-right: 1px solid darken($sidebar-bg, 5%);
      border-left: 1px solid darken($sidebar-bg, 5%);
    }

    // &::-webkit-scrollbar-track-piece {    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($sidebar-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }

    // &::-webkit-scrollbar-corner {    }

    // &::-webkit-resizer {    }
  }

  .nav {
    @include sidebar-width($sidebar-borders, $sidebar-width);
    flex-direction: column !important;

    .nav-title {
      padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
      font-size: 11px;
      font-weight: 600;
      color: $sidebar-nav-title-color;
      text-transform: uppercase;
    }

    .divider {
      height: 10px;
    }

    .nav-item {
      position: relative;
      margin: 0;
      transition: background .3s ease-in-out;

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height .3s ease-in-out;

        li {
          padding: 0;
          list-style: none;
        }
      }

      .nav-link {
        display: block;
        padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
        color: $sidebar-nav-link-color;
        text-decoration: none;
        background: $sidebar-nav-link-bg;
        @include borders($sidebar-nav-link-borders);
        @if $enable-sidebar-nav-rounded {
          border-radius: $border-radius;
        }

        &:hover {
          color: $sidebar-nav-link-hover-color !important;
          background: $sidebar-nav-link-hover-bg !important;
          @include borders($sidebar-nav-link-hover-borders);

          i {
            color: #000000;
          }
        }

        &.active {
          background: $sidebar-nav-link-active-bg !important;
          border-left: 4px solid #2595FF;
          color: #2595FF !important;
          padding-left: 20px;
          @include borders($sidebar-nav-link-active-borders);

          i {
            color: #2595FF;
          }
          &:hover {
            i {
              color: #000000;
            }
          }
        }

        [class^="icon-"], [class*=" icon-"] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        i {
          width: 20px;
          margin: 0 6px 0 0;
          font-size: 14px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
        }

        &.nav-dropdown-toggle {

          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + ($font-size-base / 4));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: ($font-size-base * 3 / 4);
            text-align: center;
            content: "";
            transition: .3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: none;
          @include borders($sidebar-nav-dropdown-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }

          > ul, > ol {
            // display: inline;
            max-height: 1000px;
          }

          .nav-link {
            color: $sidebar-nav-dropdown-color;
            font-size: 13px;
          }

          > .nav-link.nav-dropdown-toggle::before {
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }

        &.nt {
          transition: 0s !important;

          > ul, > ol {
            transition: 0s !important;
          }

          .nav-link {
            &.nav-dropdown-toggle {

              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      .nav-label {
        display: block;
        padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
        color: $sidebar-nav-title-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }

        i {
          width: 20px;
          margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
          font-size: 10px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
          vertical-align: middle;
        }
      }

      @if (lightness( $sidebar-bg ) < 40) {
        .progress {
          background-color: lighten($sidebar-bg, 15%) !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      .sidebar-nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);

        .nav-title {
          text-align: center;
        }

        .nav-item {
          width: $sidebar-compact-width;
          border-left: 0 !important;

          .nav-link {
            text-align: center;

            i {
              display: block;
              width: 100%;
              margin: $spacer / 4 0;
              font-size: 24px;
            }

            .badge {
              position: absolute;
              top: 18px;
              right: 10px;
            }

            &.nav-dropdown-toggle {

              &::before {
                top: 30px;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-minimized {
    .dropdown-nested-link-active {
      > .nav-link i {
        color: $sidebar-nav-link-active-color !important;
      }

      a {
        background-color: $sidebar-nav-link-active-bg !important;
      }
    }

    .hidden-cn {
      display: none;
    }

    .sidebar {
      z-index: $zindex-sticky - 1;

      &:hover {
        overflow: visible;
      }

      .sidebar-nav {
        overflow: visible;
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);

        .nav-title, .divider {
          display: none;
        }

        .nav-item {
          width: $sidebar-minimized-width;
          overflow: hidden;
          border-left: 0 !important;

          ul {
            background: $sidebar-bg;
          }

          .nav-link {
            position: relative;
            padding-left: 0;
            margin: 0;
            white-space: nowrap;
            border-left: 0 !important;

            &.nav-dropdown-toggle {

              &::before {
                display: none;
              }
            }

            i {
              display: block;
              float: left;
              width: $sidebar-minimized-height;
              padding: 0;
              margin: 0 !important;
              font-size: 18px;
            }

            .badge {
              position: absolute;
              // top: 14px;
              right: 15px;
              display: none;
            }

            &:hover {
              width: $sidebar-width;

              .badge {
                display: inline;
              }
            }
          }

          ul {
            position: absolute;
            left: $sidebar-minimized-width;

            li {
              position: relative;
              padding: 0;

              .nav-link {
                width: $sidebar-width - $sidebar-minimized-width;
              }

              ul, ol {
                position: absolute;
                top: 0;
                left: 100%;
              }
            }
          }

          &.nav-dropdown {
            &.open {
              //background: $sidebar-nav-link-active-bg;
              > .nav-link i {
                //color: $sidebar-nav-link-active-icon-color;
              }

              > ul, > ol {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.app-footer {
  display: block;
  min-height: $footer-height;
  padding: 0 $spacer;
  line-height: $footer-height;
  color: $footer-color;
  background: $footer-bg;
  @include borders($footer-borders);
}

@mixin switch-size($width, $height, $font-size, $handle-margin) {
  width: $width;
  height: $height;

  .switch-label {
    font-size: $font-size;
  }

  .switch-handle {
    width: $height - $handle-margin * 2;
    height: $height - $handle-margin * 2;
  }

  .switch-input:checked ~ .switch-handle {
    left: $width - $height + $handle-margin;
  }
}

@mixin switch($type, $width, $height, $font-size, $handle-margin) {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: $width;
  height: $height;
  background-color: transparent;
  cursor: pointer;

  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    @if $type == icon {
      font-family: FontAwesome;
    }
    font-size: $font-size;
    font-weight: 600;
    text-transform: uppercase;
    @if $type == ddd {
      background-color: $gray-100;
    } @else {
      background-color: #fff;
    }
    border: 1px solid $border-color;
    border-radius: 2px;
    transition: opacity background .15s ease-out;
  }
  @if $type == text or $type == icon {
    .switch-label::before,
    .switch-label::after {
      position: absolute;
      top: 50%;
      width: 50%;
      margin-top: -.5em;
      line-height: 1;
      text-align: center;
      transition: inherit;
    }
    .switch-label::before {
      right: 1px;
      color: $gray-200;
      content: attr(data-off);
    }
    .switch-label::after {
      left: 1px;
      color: #fff;
      content: attr(data-on);
      opacity: 0;
    }
  }
  .switch-input:checked ~ .switch-label {
    //background: $gray-lightest;
  }
  .switch-input:checked ~ .switch-label::before {
    opacity: 0;
  }
  .switch-input:checked ~ .switch-label::after {
    opacity: 1;
  }

  .switch-handle {
    position: absolute;
    top: $handle-margin;
    left: $handle-margin;
    width: $height - $handle-margin * 2;
    height: $height - $handle-margin * 2;
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 1px;
    transition: left .15s ease-out;
    @if $type == ddd {
      border: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    }
  }

  .switch-input:checked ~ .switch-handle {
    left: $width - $height + $handle-margin;
  }


  @if $type == ddd {
    @extend .switch-pill;
  }

  //size variations
  @if $type == default {

    &.switch-lg {
      @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, $handle-margin);
    }
    &.switch-sm {
      @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, $handle-margin);
    }
    &.switch-xs {
      @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, $handle-margin);
    }

  } @else if $type == text {

    &.switch-lg {
      @include switch-size($switch-text-lg-width, $switch-text-lg-height, $switch-text-lg-font-size, $handle-margin);
    }
    &.switch-sm {
      @include switch-size($switch-text-sm-width, $switch-text-sm-height, $switch-text-sm-font-size, $handle-margin);
    }
    &.switch-xs {
      @include switch-size($switch-text-xs-width, $switch-text-xs-height, $switch-text-xs-font-size, $handle-margin);
    }

  } @else if $type == icon {

    &.switch-lg {
      @include switch-size($switch-icon-lg-width, $switch-icon-lg-height, $switch-icon-lg-font-size, $handle-margin);
    }
    &.switch-sm {
      @include switch-size($switch-icon-sm-width, $switch-icon-sm-height, $switch-icon-sm-font-size, $handle-margin);
    }
    &.switch-xs {
      @include switch-size($switch-icon-xs-width, $switch-icon-xs-height, $switch-icon-xs-font-size, $handle-margin);
    }

  } @else if $type == ddd {

    &.switch-lg {
      @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, 0);
    }
    &.switch-sm {
      @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, 0);
    }
    &.switch-xs {
      @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, 0);
    }
  }
}

@mixin switch-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: $color !important;
    border-color: darken($color,10%);
  }

  > .switch-input:checked ~ .switch-handle {
    border-color: darken($color,10%);
  }
}

@mixin switch-outline-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: $color;

    &::after {
      color: $color;
    }
  }

  > .switch-input:checked ~ .switch-handle {
    border-color: $color;
  }
}

@mixin switch-outline-alt-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: $color;

    &::after {
      color: $color;
    }
  }

  > .switch-input:checked ~ .switch-handle {
    background: $color !important;
    border-color: $color;
  }
}

$switch-lg-width: 48px;
$switch-lg-height: 28px;
$switch-lg-font-size: 12px;

$switch-width: 40px;
$switch-height: 24px;
$switch-font-size: 10px;

$handle-margin: 2px;

$switch-sm-width: 32px;
$switch-sm-height: 20px;
$switch-sm-font-size: 8px;

$switch-xs-width: 24px;
$switch-xs-height: 16px;
$switch-xs-font-size: 7px;


$switch-text-lg-width: 56px;
$switch-text-lg-height: 28px;
$switch-text-lg-font-size: 12px;

$switch-text-width: 48px;
$switch-text-height: 24px;
$switch-text-font-size: 10px;

$switch-text-sm-width: 40px;
$switch-text-sm-height: 20px;
$switch-text-sm-font-size: 8px;

$switch-text-xs-width: 32px;
$switch-text-xs-height: 16px;
$switch-text-xs-font-size: 7px;


$switch-icon-lg-width: 56px;
$switch-icon-lg-height: 28px;
$switch-icon-lg-font-size: 12px;

$switch-icon-width: 48px;
$switch-icon-height: 24px;
$switch-icon-font-size: 10px;

$switch-icon-sm-width: 40px;
$switch-icon-sm-height: 20px;
$switch-icon-sm-font-size: 8px;

$switch-icon-xs-width: 32px;
$switch-icon-xs-height: 16px;
$switch-icon-xs-font-size: 7px;

.switch.switch-default {
  @include switch('default', $switch-width, $switch-height, $switch-font-size, $handle-margin);
}

.switch.switch-text {
  @include switch('text', $switch-text-width, $switch-text-height, $switch-text-font-size, $handle-margin);
}

.switch.switch-icon {
  @include switch('icon', $switch-icon-width, $switch-icon-height, $switch-icon-font-size, $handle-margin);
}

.switch.switch-3d {
  @include switch('ddd', $switch-width, $switch-height, $switch-font-size, 0);
}

//pills style
.switch-pill {
  .switch-label,
  .switch-handle {
    border-radius: 50em !important;
  }

  .switch-label::before {
    right: 2px !important;
  }
  .switch-label::after {
    left: 2px !important;
  }
}

@each $color, $value in $theme-colors {
  //normal style
  .switch-#{$color} {
    @include switch-variant($value);
  }
  //outline style
  .switch-#{$color}-outline {
    @include switch-outline-variant($value);
  }
  //outline alternative style
  .switch-#{$color}-outline-alt {
    @include switch-outline-alt-variant($value);
  }
}


////////// old custom

beaconstac-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

img.login-page-logo {
  max-width: 168px;
  margin-top: 1em;
  margin-bottom: 1em;
  max-height: 32px;
}

.card.table-filter-card {
  margin-bottom: 1em;
  box-shadow: none;
  border: 1px solid #c2cfd6;
}

select.custom-select.table-size-select {
  width: 6em;
  height: 100%;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

form.form-inline.filter-field-group {
  margin-bottom: 0.5em;
}

form.form-inline.filter-field-group > .filter-field-name {
  flex: 1 1 20%;
}

form.form-inline.filter-field-group > .filter-field-value {
  flex: 3 1 50%;
}

form.form-inline.filter-field-group > .filter-field-button {
  flex: 1 1 30%;
}

@media only screen and ( max-width: 721px ) {
  form.form-inline.filter-field-group > .filter-field-name {
    flex: 1 1 40% !important;
  }
  form.form-inline.filter-field-group > .filter-field-value {
    flex: 3 1 60% !important;
  }

  form.form-inline.filter-field-group > .filter-field-button {
    flex: 1 1 50% !important;
  }
}

.agm-map-container {
  height: 400px;
}

input.form-control {
  color: $input-color !important;
}

.upgrade-col {
  height: auto;
  background: #cfd3d6;
  padding-bottom: 10px;
  padding-top: 10px
}

.bstac-tint-blue {
  color: #2595ff !important;
}

.bstac-tint-disabled {
  color: darkgrey !important;
}

.highlight-on-select:hover {
  border: 2px solid #2595ff !important;
  padding: 1px;
}

.image-border {
  border: 2px solid #ffffff;
  padding: 5px 40px;
  background: #ffffff;
}

.image-border:hover {
  border-color: #2595ff !important;
}

.table-scrollable {
  max-height: 15em !important;
  overflow-y: scroll !important;
}

.table-scrollable-forms {
  max-width: 100%;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  max-lines: 1;
  text-overflow: ellipsis;
}

.place-delete {
  color: red !important;
}

.text-with-margin {
  margin-left: 8px;
  margin-right: 8px
}

.bold-on-hover:hover {
  text-shadow: 0 0 1px;
}

.list-create-button {
  color: white;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -1px;
  display: flex;
  text-align: center;
  background-color: $link-color;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
}

.detail-delete-button {
  color: red;
  margin-left: 10px;
  cursor: pointer;
}

.upgrade-col {
  height: auto;
  background: #cfd3d6;
  padding-bottom: 10px;
  padding-top: 10px
}

.selected-plan {
  border: 3px solid green !important;
  padding: 1px;
}

/* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px green;
}

.bstac-blue-background-on-hover:hover {
  background: #2595ff
}

.bstac-blue-border-on-hover:hover {
  border: solid 2px #2595ff;
  margin-top: -2px;
  margin-bottom: -2px;
}

.bstac-blue-on-hover:hover {
  color: #2595ff !important;
  font-weight: bold;
}

.danger-on-hover:hover {
  color: red !important;
}

.mcq-button {
  padding-top: 10px;;
  text-align: right;
}

.form-response-question {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.form-response-answer {
  max-width: 100px;
  overflow-x: scroll;
}

.page-header {
  font-size: 1.1rem;
  font-weight: bold;
}

.page-header i.fa {
  padding-right: 0.25em;
}

// For fixed position breadcrumb
// CoreUI's breadcrumb-fixed doesn't work properly when opening the sidebar on mobile layout
.breadcrumb-fixed-my .breadcrumb {
  position: fixed;
  z-index: 1010;
  width: 100%;
}

.breadcrumb-fixed-my .container-fluid {
  margin-top: 1.8 * $spacer;
}

.form-group label {
  font-weight: 500;
}

.card-section .card-section-header {
  padding-top: 1em;
  cursor: pointer;
  transition: color 0.2s ease;
}

.card-section .card-section-header:hover {
  color: #2595ff;
}

.card-section .card-section-header i.fa {
  padding-right: 5px;
  padding-left: 5px;
  font-size: $h5-font-size;
}

.card-section .card-section-header hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.card-section .card-section-body {
  padding-top: 1em;
}

.message-modal-content {
  text-align: center;
  background: #fcf8e3;
  padding-bottom: 10px;
  padding-top: 10px;
}

.custom-spinner-overlay {
  z-index: 11;
  border-width: 5px !important;
  border-color: #2595ff rgba(228, 229, 230, 0.3) rgba(228, 229, 230, 0.3) !important;
}

.custom-spinner-analytics {
  border-color: #2595ff rgba(228, 229, 230, 0.3) rgba(228, 229, 230, 0.3) !important;
}

.table-city-distribution {
  th, td {
    border: none !important;
  }

  font-size: 12px;
}

.analytics-card {
  box-shadow: -1px 2px 3px 2px rgba(0, 0, 0, .08);
  border: none;
  background-color: #fff;
  padding: 1rem !important;
  height: 23rem;
  border-radius: 5px;
  margin-bottom: 10px;


  h5 {
    margin-bottom: 1.2rem;
    margin-top: 0.2rem;
    padding-left: 13px;
  }
}

.analytics-download-button {
  border: none;
  margin-top: -5px;
  font-size: 15px;
  font-weight: 500;
}

.analytics-dropdown-mobile {
  margin-top: 20px;
  margin-left: 25px;
}

.analytics-dropdown-toggle::after {
  width: 11px;
  height: 24px;
  margin-left: 8px;
  margin-bottom: -21px;
}

.qr-analytics-os-chart {
  transform: translate(-10px, -15px);
}

.qr-analytics-os-ipad {
  transform: translate(-45px, -30px);
}

.analytics-date-large {
  max-width: 16rem;
}

.analytics-date-dropdown-large {
  max-width: 11rem;
}


.sidebar-minimized {
  .beaconstac-overlay {
    width: calc(100% - 50px);
  }

  @include media-breakpoint-down(md) {
    .beaconstac-overlay {
      width: calc(100%);
    }
  }
}

.sidebar-hidden {
  .beaconstac-overlay {
    width: 100%;
  }
}

.beaconstac-overlay {
  position: fixed;
  width: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 55px);
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  z-index: 11;

  .custom-spinner-overlay {
    margin-top: 55px;
  }

  &.include-breadcrumb {
    margin-top: 0;
  }

  &.full-layout {
    width: 100%;
    margin-top: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 1024;
    height: 100vh;
    .loader {
      top: calc(50% - 32px);
    }
  }
}

// For the overlay that appears when the sidebar is collapsed
.collapsed-overlay{
  width: calc(100% - 60px) !important;
}

@include media-breakpoint-down(md) {
  .beaconstac-overlay {
    width: 100%;
  }
  .action-button-mobile {
    width: 100% !important;
  }
  .place-dropdown-analytics-sm {
    margin-left: 0 !important;
  }
  .mobile-button-margin {
    margin-bottom: 2px;
  }
  .change-plan-view {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .list-create-button {
    margin-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }

}

// To prevent scrollbars from appearing during animations
.card {
  overflow: hidden !important;
}

.qr-table-custom {
  .card {
    overflow: visible !important;
  }
}

.modal {
  z-index: 2147483003 !important;
}

// Fix for error in CoreUI
.modal-open {
  .modal {
    display: none !important;
  }

  .modal.show {
    display: block !important;
  }
}

.analytics-error {
  text-align: center;
  font-size: 18px;
  margin-top: 140px;
  margin-bottom: 140px;
}

.analytics-date-picker {
  width: 180px !important;
  display: inline-block !important;
  margin-bottom: 2px !important;
}

.analytics-export-date-picker {
  width: 100px !important;
  display: inline-block !important;
  margin-bottom: 2px !important;
}

.analytics-export-selected-date {
  color: $beaconstacBlue;
}

.export-date-option {
  border-radius: 5px;
  &:hover {
    background-color: #eee;
  }
}

.place-dropdown-analytics {
  display: inline-block;
  margin-left: 1em;
  width: 180px;
}

.spinner-container-analytics {
  text-align: center;
  margin-top: 240px;
  margin-bottom: 240px;
}

.spinner-container-analytics-v2 {
  text-align: center;
  margin-top: 140px;
  margin-bottom: 240px;
}

.spinner-container-top-charts {
    text-align: center;
    margin-top: 180px;
    margin-bottom: 180px;
}

.spinner-container-analytics-card {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 90px;
}

.text-with-margin-analytics {
  display: inline-block;
  margin: 1em;
}

.performance-overview-card {
  height: 100px;
  text-align: center;
}

.switch {
  .switch-label {
    background-color: $gray-200 !important;
  }

  .switch-label:before {
    color: white !important;
  }
}

.dropdown-menu {
  border-color: #d4d4d4 !important;

  .dropdown-header, .dropdown-item {
    border-color: #d4d4d4 !important;
  }

  .dropdown-header {
    border-bottom: 1px solid #d4d4d4 !important;
  }

  .dropdown-item i {
    color: $text-muted;
  }

  .dropdown-header.dropdown-header-clickable {
    cursor: pointer;
  }

  .dropdown-header.dropdown-header-clickable:hover {
    color: #2595ff;
  }
}

.app-header {
  .navbar-brand {
    background-position: 0 center !important;
    border-left: 16px solid transparent;
  }

  .navbar-toggler {
    color: $white !important;
  }

  .navbar-nav .nav-item .nav-link {
    color: $white !important;

    .fa {
      padding-right: 3px;
    }
  }

  .navbar-nav .nav-item .nav-link:hover {
    color: #2595ff !important;
  }
}

.dashboard-card-text {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  font-size: 16px;
  color: white;
}

.sidebar {
  .nav {
    .nav-title {
      font-size: 14px;
    }
  }
}

.download-csv-forms {
  margin-top: -51px;
}

input.ng-dirty.ng-invalid {
  box-shadow: 0 0 1pt 1pt red !important;
}

.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sort-icon {
  margin-top: 4px !important;
  color: #adb5bd;
  cursor: pointer;
}

.tooltip {
    z-index: 2147483010;
}

.tooltip-inner {
    max-width: 448px !important;
    padding: 0px 0px !important;
    background: #16212F !important;
    border-radius: 16px !important;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: #16212F !important;
    top: -5px;
    right: -3px;
    border-width: 7.5px 9px 7.5px 0px;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: #16212F !important;
    top: -5px;
    left: -3px;
    border-width: 7.5px 0 9px 7.5px;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #16212F !important;
    top: -3px;
    left: -5px;
    border-width: 0px 7.5px 9px 7.5px;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #16212F !important;
    top: -3px;
    right: -5px;
    border-width: 9px 7.5px 0px 7.5px;
}

.tooltip.show {
    opacity:1;
}

.table-responsive-all-sizes {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

  // Prevent double border on horizontal scroll due to use of `display: block;`
  &.table-bordered {
    border: 0;
  }

}

.ui-select-multiple {
  .ui-select-match-item {
    outline: 0;
    font-size: 0.75rem;
    padding: 6px;
    color: $gray-700;
    margin: 0 2px 2px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px $gray-400;
    background-color: transparent;
    border-radius: 2px;
    cursor: pointer;
  }

  .ui-select-match-item:hover {
    color: $blue;
  }
}

.ui-select-multiple {
  .ui-select-match-item {
    outline: 0;
    font-size: 0.75rem;
    padding: 6px;
    color: $gray-700;
    margin: 0 2px 2px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px $gray-400;
    background-color: transparent;
    border-radius: 2px;
    cursor: pointer;
  }

  .ui-select-match-item:hover {
    color: $blue;
  }
}

.ui-select-toggle {
  background-color: white !important;
  overflow: hidden;
}

.dashboard-no-data-container {
  position: absolute;
  z-index: 111;
  height: 100%;
  width: 100%;
  margin-top: 120px;
  font-size: 18px;
}

.light-colored-text {
  color: #585858;
  font-size: 16px;
}

.dashboard-loading-text {
  font-size: 20px;
  color: dimgray;
}

.background-image-container {
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
}

.order-summary-container {
  margin-top: 10px;
  margin-left: -10px;
  background: #eceff1
}

@include media-breakpoint-down(sm) {
  .order-summary-container {
    margin-left: 8px;
    margin-right: 8px;
  }
  .dashboard-content-flex-width {
    width: 100% !important;
    min-width: 250px;
  }
  .dashboard-graph-flex-width {
    height: 100px;
    width: 100% !important;
    min-width: 250px;
    margin-top: 10px !important;
  }
  .dashboard-duration-flex {
    width: 100%;
  }
  .distribution-dropdown-container, .landing-page-dropdown-container {
    width: 100% !important;
  }
}

.app {
  overflow-y: scroll;
}

.hidden {
  display: none;
}

.dashboard-content-flex-width {
  width: 35%;
  min-width: 250px;
}

.dashboard-graph-flex-width {
  height: 100px;
  width: 60%;
  min-width: 250px;
}

.dashboard-current-value {
  font-size: 15px;
  color: #15548f;
}

.dashboard-previous-value {
  font-size: 15px;
  color: #2595ff;
}

.dashboard-comparison {
  font-size: 15px;
}

.dashboard-value-large {
  font-size: 20px !important;
}

.dashboard-date-style {
  border-radius: 5px;
  color: white
}

.ngx-charts-tooltip-content {
  background: whitesmoke !important;
  opacity: 1 !important;
  display: block;
}

.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-right {
    border-right: whitesmoke solid 7px !important;
  }

  .tooltip-caret.position-left {
    border-left: whitesmoke solid 7px !important;
  }

  .tooltip-caret.position-top {
    border-top: whitesmoke solid 7px !important;
  }

  .tooltip-caret.position-bottom {
    border-bottom: whitesmoke solid 7px !important;
  }
}

.switch-disabled {
  cursor: default;

  .switch-input:checked ~ .switch-label {
    background-color: $gray-200 !important;
    border-color: $gray-200 !important;
  }

  .switch-input:checked ~ .switch-handle {
    border-color: $gray-200 !important;
  }
}

.nav-dropdown {
  .nav-link.nav-dropdown-toggle::before {
    transform: rotate(-90deg) !important;
  }

  &.open {
    .nav-link.nav-dropdown-toggle::before {
      transform: rotate(-270deg) !important;
    }
  }
}

.register-welcome-text {
  font-size: 1.6rem;
  font-weight: 300;
}

.analytics-performance-no-data {
  position: absolute;
  z-index: 11;
  width: 100%;
  margin-top: 18%;
  font-size: 20px;
  font-weight: 400;
}

.hide-by-opacity {
  opacity: 0;
}

.login-background {
  background-color: white;
}

.login-container {
  width: 100%;
}

.login-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px;
  border-color: #000000 rgba(0, 0, 0, 0.21) rgba(0, 0, 0, 0.21);
}

.login-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.login-page-text {
  font-size: 13pt;
  margin-bottom: 0.3rem;
}

.login-input-box {
  height: 3rem;
  border-radius: 6px;
}

.login-page-welcome-text {
  font-size: 25pt;
  color: #2595FF;
}

.login-page-welcome-subtext {
  font-size: 13pt;
}

.login-content-container {
  width: 60%;
}

.login-button {
  width: 100%;
  background-color: #2595FF !important;
  height: 3rem;
  border-radius: 6px;
}

.register-side-background {
  background-color: #2595FF;
  margin-top: -2px;

  .login-page-welcome-text {
    color: white;
  }

  .login-page-welcome-subtext {
    color: white;
  }
}

.login-header-text {
  margin-top: 1rem;
}

.modal-center-header {
  width: 100%;
  text-align: center;
}

.distribution-dropdown-container {
  display: inline-block;
  width: 250px;
  min-width: max-content;
  margin-bottom: 25px;
}

.new-dropdown-container {
  display: inline-block;
  width: 250px;
  min-width: max-content;
}

.landing-page-dropdown-container, .form-dropdown-container{
  width: 275px;
  min-width: max-content;
}

.ui-select-choices {
  width: auto !important;
  min-width: 100%;
  display: block;
  // Fix for ng2-select for safari. Safari doesn't properly handles div in the dropdown of ng2 select
  .dropdown-item > div {
    pointer-events: none;
  }
}

.ui-select-match-text {
  padding-right: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-no-drop {
  cursor: no-drop;
}

.modal-content {
  border-radius: .3rem;
}

.modal-header {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.modal-warning.modal-header {
  //background: ;
}

.max-width-transition {
  transition: max-width ease 0.4s !important;
}

ng-select.custom-ng-select-overview > div {
  padding: 5px 8px !important;
  border: none;
  background-color: #f1f4f8;

  & > input {
    height: 30px !important;
  }

  & > input::placeholder {
    font-size: 12px;
  }

}

ng-select.custom-ng-select-overview > div {
  padding: 5px 8px !important;
  border: none;
  background-color: #f1f4f8;

  & > input {
    height: 30px !important;
  }

  & > input::placeholder {
    font-size: 12px;
  }

}

.select-no-value-error {
  color: #fff;
  text-decoration: none;
  outline: 0;
  margin-top: -1px;
  background-color: #428bca;
  padding: .3em 0;
  text-align: center;
  border: solid 1px;
}

.select-fetching-spinner {
  margin-top: 4px;
  border-color: #ffffff rgba(228, 229, 230, 0.3) rgba(228, 229, 230, 0.3) !important;
}

.pac-container {
  z-index: 9999999999;
}

.dashboard-dropdown {
  position: absolute !important;
}

.dropdown-button {
  background: white;
  padding: 0.5rem;
  border-radius: 5px;
  border: solid 1px rgba(128, 128, 128, 0.36);
  text-align: center;
  width: 8em;
  min-width: max-content;
}

.empty-list-icon {
  color: #e9e9e9;
  font-size: 100px
}

.empty-state-img{
  height: 10rem;
}

.empty-list-heading{
  font-weight: bold;
  font-size: 20px;
}

.empty-list-text {
  font-size: 15px;
  text-align: center;
}

.create-multi-option-dropdown {
  margin: 0 !important;
  display: inline !important;
  padding: 0.5rem 0.5rem !important;
  border-left: 1px solid #e9e9e9 !important;


}

.create-dropdown {
  > .dropdown-menu {
    -webkit-transform: translateY(8px) !important;
    -moz-transform: translateY(8px) !important;
    -ms-transform: translateY(8px) !important;
    -o-transform: translateY(8px) !important;
    transform: translateY(8px) !important;
  }
}

.content-editable {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: auto;

  &:hover {
    box-shadow: 0 0 3px 2px #2595ff;
  }

  &:focus {
    overflow: auto;
    white-space: normal;
  }
}

@media only screen and (max-width: 530px) {
  .main .container-fluid {
    padding: 0;
  }
  .container-fluid {
    padding: 0;
  }

  .empty-list-text {
    max-width: 250px;
  }

  .app-body{
    overflow-x: unset;
  }
}

.nav {
  font-size: 15px;
}

.sidebar {
  .nav {
    .nav-item {
      .nav-link {
        padding: 16px 1.5rem;
      }
    }
  }
}

.dropdown-item {
  padding: 0.55rem 1rem;
  font-size: 13px;
  border-bottom: none;
}

.sidebar .nav .nav-item .nav-link:hover {
  color: #16212F !important;
  background: #F4F5F7 !important;
}

.sidebar .nav .nav-item .nav-link.inner-link {
  font-size: 13px;
}

.badge {
  border-radius: 2px;
}

.badge-yellow {
  background: #FFF0C8;
  color: #FCBF49;
  padding: 4px 8px;
  margin-left: 8.5px;
  float: none !important;
}

.social-share-button {
  padding: 5px 15px !important;
  display: flex !important;
  align-items: center;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;

  &.facebook-button {
    background: #1877f2;
  }

  &.twitter-button {
    background: #1ea1f2;
  }

  &.linkedin-button {
    background: #2866b2;
  }
}

.btn-primary {
  text-transform: uppercase;

  &:hover {
    background-color: #0276ff;
  }

  &:disabled {
    background-color: rgba(37, 149, 255, 0.8) !important;
    border-color: rgba(37, 149, 255, 0.8) !important;
    cursor: not-allowed;
    opacity: 1;
  }
}

.beaconstac-message-CTA {
  color: #2595FF;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
}

body, .app-footer {
  color: #404040;
}

.app-footer {
  background: inherit !important;
}

.btn-secondary {
  background-color: #dedede;
  border-color: #dedede;

  &:hover {
    background: #f1f1f1;
  }
}

.table-bordered td, th {
  border-left: none !important;
  border-right: none !important;
  vertical-align: middle;
}

.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  border: none;
}

.sidebar-minimized {
  .time-remaining-text {
    display: none !important;
  }
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  color: #444;
}

.breadcrumb {
  padding-left: 30px;
}


// Dot floating
.dot-floating {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2595ff;
  color: #2595ff;
  animation: dot-floating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
}

.dot-floating::before, .dot-floating::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-floating::before {
  left: -26px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2595ff;
  color: #2595ff;
  animation: dot-floating-before 3s infinite ease-in-out;
}

.dot-floating::after {
  left: -54px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2595ff;
  color: #2595ff;
  animation: dot-floating-after 3s infinite cubic-bezier(0.4, 0, 1, 1);
}

@keyframes dot-floating {
  0% {
    left: calc(-50% - 5px);
  }
  75% {
    left: calc(50% + 105px);
  }
  100% {
    left: calc(50% + 105px);
  }
}

@keyframes dot-floating-before {
  0% {
    left: -50px;
  }
  50% {
    left: -26px;
  }
  75% {
    left: -50px;
  }
  100% {
    left: -50px;
  }
}

@keyframes dot-floating-after {
  0% {
    left: -100px;
  }
  50% {
    left: -54px;
  }
  75% {
    left: -100px;
  }
  100% {
    left: -100px;
  }
}

// Dot floating end


.g-signin2 {
  .abcRioButton {
    width: 100% !important;
  }
}

// External login buttons

.external-login-container {
  font-family: 'Roboto', sans-serif;
  border: solid 1px #c2cfd6;
  font-weight: 600;
  border-radius: 6px;
  color: #536c79;

  &:hover {
    background: #2595ff1a;
    color: #2595ff !important;
    border-color: #2595ff4d;

    .login-button-icon {
      border-color: #2595ff4d;
    }
  }

  .login-button-icon {
    font-size: 18px;
    padding: 8px;
    min-width: 34px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: 1px solid #c2cfd6;
    color: #536c79 !important;
  }

  .button-text {
    padding-left: 12px
  }
}

// External login buttons

.side-notification {
  position: fixed;
  right: -300px;
  bottom: 100px;
  background: #fff;
  padding: 24px;
  border: 1px solid #e9e9e9;
  max-width: 300px;
  transition: right 1000ms ease-in-out;
  box-shadow: -6px 4px 20px 6px rgba(130, 130, 130, 0.33);
  border-radius: 8px;
  z-index: 11111;

  &.active {
    right: 40px;
  }
}

// date picker
body .bs-datepicker-container {
  padding: 0px;

  .bs-datepicker-head {
    background-color: $beaconstacBlue;
  }

  .bs-datepicker-body table td span.selected,
  .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*="select-"]:after,
  .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $beaconstacBlue; }
}

.dropdown-divider{
  border-top: none;
}

.ngx-select__toggle.btn.form-control:hover{
  background-color: transparent !important;
  border-color: #c2cfd6 !important;
  cursor: pointer !important;
}



// fixes


.table-responsive {
  display: inline-table !important;
}

.table-bordered {
  border: $table-border-width solid $table-border-color !important;
}

//.form-control {
//  height: auto;
//}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0 !important;
  }
}

.flex-fill {
  flex: unset !important;
}

.save-button {
  display: block !important;
}

button.close {
  padding: unset!important;
  margin: unset!important;
}

// Fix pop-up on LP
.sidebar {
  box-shadow: 2px 2px 8px rgba(129, 132, 136, 0.15);
  z-index: 4 !important;
}

.nav-dropdown-toggle {
  font-size: 15px !important;
}

.notifications-badge {
  position: absolute;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-left: -5px;
  background-color: #FF445E;
}

.sidebar-fixed .app-header + .app-body .sidebar {
  box-shadow: 2px 2px 8px rgba(129, 132, 136, 0.15);
  z-index: 5 !important;
}

.sidebar .nav-link:hover.nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23536c79' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.dropdown-options div:hover {
  color: #2595FF !important;
}

@media only screen and (max-width: 992px) {
  .sidebar .sidebar-nav, .sidebar .nav, .sidebar-mobile-show .sidebar {
    width: 100vw !important;
  }

  .header-fixed .app-body {
    margin-top: 0 !important;
  }
}

.sidebar .nav .nav-dropdown-items .nav-item .nav-link.active {
  padding-left: 3.25rem !important;
}

.main-error {
  color: #FF445E
}

@media only screen and (max-width: $bx--md) {
  .bx--md-down-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bx--md-down-block {
    display: block !important;
  }
  .bx--md-down-mt-4 {
    margin-top: 1.5rem !important;
  }
  .bx--md-down-mt-2 {
    margin-top: 0.5rem !important;
  }
  .bx--md-down-ml-0 {
    margin-left: 0 !important;
  }
  .bx--md-down-px-4 {
    padding-inline: 1.5rem !important;
  }
  .bx--md-down-float-unset {
    float: unset !important;
  }
  .bx--md-down-display-none {
    display: none !important;
  }
  .analytics-edit-button {
    margin-top: 16px !important;
    padding-top: 0 !important;
  }
  .bx--md-down-px-0 {
    padding-inline: 0 !important;
  }
}
@media only screen and (min-width: $bx--md) {
  .bx--md-above-display-none {
    display: none !important;
  }
}
@media only screen and (max-width: 992px) {
  .breadcrumb-top-margin-issue {
    padding-top: 1.5rem;
  }
}
@media only screen and (max-width: $bx--sm) {
  .bx--sm-fixed-max-width-fit-content {
    max-width: fit-content !important;
  }
  .modal.modal-fullscreen .modal-dialog {
    width: 100vw !important;
  }
  .bx--sm-down-mt-4 {
    margin-top: 1.5rem !important;
  }
  .bx--sm-down-mt-2 {
    margin-top: 0.5rem !important;
  }
  .bx--sm-down-mt-3 {
    margin-top: 1rem !important;
  }
  .links-section-mobile {
    flex-direction: column;
  }
  .links-section-mobile-reverse {
    flex-direction: column-reverse;
  }
  .bx--sm-fixed-max-width-66 {
    max-width: 66.66%;
  }
  .bx--sm-down-display-none {
    display: none !important;
  }
  .bx--sm-down-block {
    display: block !important;
  }
  .bx--sm-down-width-fill-available {
    width: -webkit-fill-available !important;
    button {
      width: -webkit-fill-available !important;
    }
  }
  .bx--sm-down-px-0 {
    padding-inline: 0 !important;
  }
  .bx--sm-below-height-200 {
    height: 200px;
  }
  .sm-and-below-full-width-options {
    width: 100vw !important;
  }
  .sm-and-below-position-unset {
    position: unset;
  }
}
.width-fill-available {
  width: -webkit-fill-available !important;
  button {
    width: -webkit-fill-available !important;
  }
}
@media only screen and (min-width: $bx--sm) {
  .bx--sm-above-height-400 {
    height: 400px;
  }
  .bx--sm-above-display-none {
    display: none !important;
  }
  .bx--sm-above-mr-3 {
    margin-right: 1rem !important;
  }

}

.drag-overflow-disable {
  overflow: hidden;
  height: 100%;
}

.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}

.beaconstac-danger {
  color: rgb(255, 68, 94);
}

@media screen and (max-width: 992px) {
  .container-fluid-xs {
    margin-top: 1.5 * $spacer !important;
  }
  .container-margin-top {
    margin-top: 8.5 * $spacer !important;
  }
}

.font-fs-12 {
  font-size: 12px;
}

.font-fs-13 {
  font-size: 13px;
}

.font-fs-18 {
  font-size: 18px;
}

.font-fs-16 {
  font-size: 16px;
}

.font-fs-20 {
    font-size: 20px;
}

.beaconstac-label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.25px;
    color: #16212F;
    margin-bottom: 0.5rem;
}

.crown-color {
  color: #fcbf49;
}
